import axios from "axios";
import { axiosErrorHandler } from "./axiosErrorHandler";
import { setAuthHeader } from "./setAuthHeader";

const BaseURL = process.env.REACT_APP_API_URL;

export const createApi = (path: string ,query: string) => {
  //axios.defaults.baseURL = window.location.protocol + '//' + window.location.hostname + ':' + 8080;
  // create axios instance
  const api = axios.create({
    baseURL: BaseURL + path + query,
    headers: setAuthHeader(),
  });

  // add global error handler
  // api.interceptors.response.use(
  //   (res) => {
  //     return res;
  //   },
  //   (error) => {
  //     return axiosErrorHandler(error);
  //   }
  // );
  return api;
};

export const createAlertApi = (query: string) => {
  //axios.defaults.baseURL = window.location.protocol + '//' + window.location.hostname + ':' + 8080;
  // create axios instance
  const api = axios.create({
    baseURL: "/alert/" + query,
    headers: setAuthHeader(),
  });

  // add global error handler
  api.interceptors.response.use(
    (res) => {
      return res;
    },
    (error) => {
      return axiosErrorHandler(error);
    }
  );
  return api;
};
