import axios, { AxiosError } from "axios";
import notify from "devextreme/ui/notify";
import Cookies from "universal-cookie";

// Axios error handler
const cookies = new Cookies();
export const axiosErrorHandler = (error: Error | AxiosError) => {
  if (axios.isAxiosError(error)) {
    if (error?.response?.status === 401 && error?.config?.url !== "/token/") {
      cookies.remove("token", { path: "/" });
      window.location.href = "/login";
    }
    notify(
      {
        message: `${error} (${error?.response?.data})`,
        position: "top center",
        showCloseButton: true,
      },
      "error",
      5000
    );
    if (error?.response) {
      // console.log(error?.response?.data);
      // console.log(error?.response?.status);
      // console.log(error?.response?.headers);
    } else if (error?.request) {
      // console.log(error?.request);
    } else {
      // console.log("Error", error?.message);
    }
    // console.log(error?.config);
  }
};
