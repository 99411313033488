import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AuthService } from "../../utils/authService";

export interface userStateType {
  username: string;
  is_superuser: boolean;
  is_active: boolean;
}
export interface UserState {
  value: userStateType | undefined;
}

const authService = new AuthService();
const initialState: UserState = {
  value: {
    username: authService.getUserFromCookie()?.username,
    is_superuser: authService.getUserFromCookie()?.is_superuser,
    is_active: authService.getUserFromCookie()?.is_active,
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserByValue: (state, action: PayloadAction<userStateType>) => {
      state.value = action.payload;
    },
    setUserToNull: (state) => {
      state.value = undefined;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserByValue, setUserToNull } = userSlice.actions;

export default userSlice.reducer;
