import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import { PrivateRoute } from "./PrivateRoute";

import SuspenseLoader from 'src/components/SuspenseLoader';


import { Routes, Route } from "react-router-dom";

import { useSelector } from "react-redux";
import { RootState } from './redux/store';


const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages
const Login = Loader(lazy(() => import('src/content/pages/Login')))

// Dashboards

// const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));

const Commissioners = Loader(
  lazy(() => import('src/content/applications/Commissioner'))
);

const Introducers = Loader(
  lazy(() => import('src/content/applications/Commissioner'))
);

const Players = Loader(
  lazy(() => import('src/content/applications/Player'))
);

const Invoices = Loader(
    lazy(() => import('src/content/applications/Invoice'))  
);

// Applications

const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger'))
);
const Settings = Loader(
  lazy(() => import('src/content/applications/Settings'))
);
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

// Components

const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);



export const AppRoutes = () => {
  const username = useSelector((state: RootState) => state.user.value?.username);
  const is_superuser = useSelector((state: RootState) => state.user.value?.is_superuser);
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/login" element={<Login />} />
      <Route element={<PrivateRoute isAllowed={username ? true : false} redirectPath="/login" />}>
        <Route path="/dashboards" element={<SidebarLayout /> }>
          <Route path="introducer" element={<Introducers /> }></Route>          
          <Route path="commissioner" element={<Commissioners /> }></Route>
          <Route path="player" element={<Players /> }></Route>
          <Route path="invoice" element={<Invoices /> }></Route>   
          <Route path="settings" element={<Settings /> }></Route>        
        </Route>
      </Route>
      <Route path="*" element={<Status404 />} />
    </Routes>
  );
};


// const routes: RouteObject[] = [

//   {
//     path: 'dashboards',
//     element: <SidebarLayout />,
//     children: [
//       {
//         path: '',
//         element: <Navigate to="crypto" replace />
//       },
//       {
//         path: 'crypto',
//         element: <Crypto />
//       },
//       {
//         path: 'messenger',
//         element: <Messenger />
//       },
//       {
//         path: 'transactions',
//         element: <Transactions />
//       },      
//     ]
//   },
//   // {
//   //   path: 'management',
//   //   element: <SidebarLayout />,
//   //   children: [
//   //     {
//   //       path: '',
//   //       element: <Navigate to="transactions" replace />
//   //     },
//   //     {
//   //       path: 'transactions',
//   //       element: <Transactions />
//   //     },
//   //     {
//   //       path: 'profile',
//   //       children: [
//   //         {
//   //           path: '',
//   //           element: <Navigate to="details" replace />
//   //         },
//   //         {
//   //           path: 'details',
//   //           element: <UserProfile />
//   //         },
//   //         {
//   //           path: 'settings',
//   //           element: <UserSettings />
//   //         }
//   //       ]
//   //     }
//   //   ]
//   // },
//   // {
//   //   path: '/components',
//   //   element: <SidebarLayout />,
//   //   children: [
//   //     {
//   //       path: '',
//   //       element: <Navigate to="buttons" replace />
//   //     },
//   //     {
//   //       path: 'buttons',
//   //       element: <Buttons />
//   //     },
//   //     {
//   //       path: 'modals',
//   //       element: <Modals />
//   //     },
//   //     {
//   //       path: 'accordions',
//   //       element: <Accordions />
//   //     },
//   //     {
//   //       path: 'tabs',
//   //       element: <Tabs />
//   //     },
//   //     {
//   //       path: 'badges',
//   //       element: <Badges />
//   //     },
//   //     {
//   //       path: 'tooltips',
//   //       element: <Tooltips />
//   //     },
//   //     {
//   //       path: 'avatars',
//   //       element: <Avatars />
//   //     },
//   //     {
//   //       path: 'cards',
//   //       element: <Cards />
//   //     },
//   //     {
//   //       path: 'forms',
//   //       element: <Forms />
//   //     }
//   //   ]
//   // }
// ];

// export default routes;
