import { createApi } from "./baseApi";
import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";
/*
eslint padding-line-between-statements: [
  'error',
  { "blankLine": "never", "prev": "*", "next": "*" }
]
*/
export class AuthService {
 private authApi : any
 private cookies : any
constructor() {
  this.authApi = createApi("/social/","");
  this.cookies = new Cookies();
}
  login = async (username: string, password: string) => {
    const res = await this.authApi.post("/token/", {
      username,
      password,
    });
    if (res?.status === 200) {
      if (res && res.data && res.data.access) {
        this.cookies.set("token", res.data.access, {
          path: "/",
          maxAge: 30 * 24 * 60 * 60 * 1000,
        });
        return { status: "success" };
      }
      return res.data;
    }
    return null;
  };
  logout = () => {
    this.cookies.remove("token", { path: "/" });
    window.location.href = "/login";
  };
  getUserFromCookie = () => {
    const token = this.cookies.get("token");
    if (token) {
      const decodedToken: any = jwt_decode(token);
      return decodedToken;
    }
    return  { username:null, is_superuser:null, is_active:null };
  };
  validateToken = async () => {
    const res = await this.authApi.get("/users/validate");
    if (res?.status === 200) {
      return true;
    }
    return false;
  };
}
