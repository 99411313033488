import { Navigate, Outlet } from "react-router-dom";

interface Props {
  isAllowed: boolean;
  redirectPath?: string;
  children?: any;
}

export const PrivateRoute = ({ isAllowed, redirectPath = "/login", children }: Props) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};
